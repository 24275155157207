import { ORDER_RECEIVED_PHASE_NAMES, phaseSx } from '@asaprint/common/constants/OrderReceived.js';
import { Box } from '@mui/material';
import React from 'react';

function OrderReceivedPhaseCell({ value }: { value: string }) {
  return (
    <Box
      sx={{
        textAlign: 'center',
        borderRadius: '1em',
        mx: 1,
        px: 2,
        width: 'calc(100% - 10px)',
        ...phaseSx[value],
      }}
    >
      {ORDER_RECEIVED_PHASE_NAMES[value]}
    </Box>
  );
}

OrderReceivedPhaseCell.displayName = 'OrderReceivedPhaseCell';

export default OrderReceivedPhaseCell;
