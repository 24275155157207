import { DeliveryTypes } from '@asaprint/common/constants/DeliveryType.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import {
  GraphQLReactTable as TGraphQLReactTable,
  GraphQLReactTableProps,
  ReactTableRef,
} from '@asaprint/asap/components/ReactTable.js';
import CenteredCell from '@asaprint/asap/components/tables/CenteredCell.js';
import DateCell from '@asaprint/asap/components/tables/DateCell.js';
import DateFilter from '@asaprint/asap/components/tables/DateFilter.js';
import OrderReceivedPhaseCell from '@asaprint/asap/components/tables/OrderReceivedPhaseCell.js';
import OrderReceivedPhaseFilter from '@asaprint/asap/components/tables/OrderReceivedPhaseFilter.js';
import OrderReceivedStatusCell from '@asaprint/asap/components/tables/OrderReceivedStatusCell.js';
import OrderReceivedStatusFilter from '@asaprint/asap/components/tables/OrderReceivedStatusFilter.js';
import Loading from '@engined/client/components/Loading.js';
import SelectFilter, { Option } from '@engined/client/components/table/SelectFilter.js';
import UserCell from '@asaprint/asap/components/tables/UserCell.js';
import UserFilter from '@asaprint/asap/components/tables/UserFilter.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import useUserSettings from '@asaprint/asap/hooks/useUserSettings.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { ORDERS_RECEIVED_TITLE } from '@asaprint/asap/locales/client.js';
import { DASHBOARD_ROUTE, ORDERS_RECEIVED_SHOW_ROUTE } from '@asaprint/asap/routes.js';
import {
  OrdersReceived_Load,
  OrdersReceived_LoadTable,
} from '@asaprint/asap/routes/__authenticated/orders/received/index.graphql';
import {
  OrderReceivedFilter,
  OrdersReceived_LoadQuery,
  OrdersReceived_LoadQueryVariables,
  OrdersReceived_LoadTableQuery,
  OrdersReceived_MoneyDeliveryTypeFragment,
  OrdersReceived_MoneyPaymentTypeFragment,
  OrdersReceived_OrderReceivedFragment,
} from '@asaprint/asap/schema.client.types.js';
import { Permission, Role } from '@asaprint/common/access.js';
import { OrderReceivedPhase } from '@asaprint/common/constants/OrderReceived.js';
import { ProductStatus } from '@asaprint/common/constants/Product.js';
import { TaskType } from '@asaprint/common/constants/Task.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { url } from '@engined/core/services/routes.js';
import { faCommentsDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, ButtonGroup } from '@mui/material';
import React, {
  ForwardRefExoticComponent,
  MemoExoticComponent,
  PropsWithoutRef,
  RefAttributes,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Column, Row, TableState } from 'react-table';

interface Filter {
  needAccount: boolean;
  dpdExpedition: boolean;
  dpdPackaging: boolean;
  pickup: boolean;
}

const initialFilter: Filter = {
  needAccount: false,
  dpdExpedition: false,
  dpdPackaging: false,
  pickup: false,
};

interface OwnProps {}

type Props = OwnProps;
const GraphQLReactTable = TGraphQLReactTable as unknown as MemoExoticComponent<
  ForwardRefExoticComponent<
    PropsWithoutRef<GraphQLReactTableProps<OrdersReceived_OrderReceivedFragment>> &
      RefAttributes<ReactTableRef<OrdersReceived_OrderReceivedFragment>>
  >
>;

const emptyArray = [];

const mapData = (data: OrdersReceived_LoadTableQuery) => data?.ordersReceived?.edges.map((e) => e.node) || emptyArray;
const mapCount = (data: OrdersReceived_LoadTableQuery) => data?.ordersReceived?.pageInfo.count || 0;
const defaultSortBy: TableState['sortBy'] = [{ id: 'moneyCreatedAt', desc: true }];

const getRowSx = ({ original }: Row<OrdersReceived_OrderReceivedFragment>) => {
  return (original.phase === OrderReceivedPhase.INVOICE || original.phase === OrderReceivedPhase.WAITING_FOR_INVOICE) &&
    original.invoicingNote
    ? {
        backgroundColor: '#fff3cd',
      }
    : null;
};

const Page: React.FunctionComponent<Props> = () => {
  const { t } = useLocale();
  const { requestUser } = useAuth();
  const reactTableRef = useRef<ReactTableRef<OrdersReceived_OrderReceivedFragment>>();
  const navigate = useNavigate();
  const [customFilter, setCustomFilter] = useUserSettings<Filter>('OrdersReceived__customFilter_v3', initialFilter);

  const { loading, data, error } = useQuery<OrdersReceived_LoadQuery, OrdersReceived_LoadQueryVariables>(
    OrdersReceived_Load,
  );

  const moneyDeliveryTypeEdges = data?.moneyDeliveryTypes?.edges;
  const moneyDeliveryTypeOptions = useMemo<Option[]>(
    () =>
      moneyDeliveryTypeEdges?.map((e) => ({
        label: e.node.name,
        value: e.node.id,
      })) || emptyArray,
    [moneyDeliveryTypeEdges],
  );
  const moneyDeliveryTypesById = useMemo<{ [id: string]: OrdersReceived_MoneyDeliveryTypeFragment }>(
    () =>
      moneyDeliveryTypeEdges?.reduce<{ [id: string]: OrdersReceived_MoneyDeliveryTypeFragment }>((acc, cur) => {
        acc[cur.node.id] = cur.node;
        return acc;
      }, {}) || {},
    [moneyDeliveryTypeEdges],
  );

  const moneyPaymentTypeEdges = data?.moneyPaymentTypes?.edges;
  const moneyPaymentTypeOptions = useMemo<Option[]>(
    () =>
      moneyPaymentTypeEdges?.map((e) => ({
        label: e.node.name,
        value: e.node.id,
      })) || emptyArray,
    [moneyPaymentTypeEdges],
  );
  const moneyPaymentTypesById = useMemo<{ [id: string]: OrdersReceived_MoneyPaymentTypeFragment }>(
    () =>
      moneyPaymentTypeEdges?.reduce<{ [id: string]: OrdersReceived_MoneyPaymentTypeFragment }>((acc, cur) => {
        acc[cur.node.id] = cur.node;
        return acc;
      }, {}) || {},
    [moneyPaymentTypeEdges],
  );

  const onShowAllClick = useEventCallback(() => {
    setCustomFilter((state) => ({
      ...state,
      needAccount: false,
    }));
    if (reactTableRef.current) {
      reactTableRef.current.setAllFilters([]);
    }
  });

  const onNeedAccountClick = useEventCallback(() => {
    setCustomFilter((state) => ({
      ...state,
      needAccount: true,
    }));
    if (reactTableRef.current) {
      reactTableRef.current.setAllFilters([]);
    }
  });

  const onRowClick = useEventCallback((row: Row<OrdersReceived_OrderReceivedFragment>) => {
    navigate(url(ORDERS_RECEIVED_SHOW_ROUTE, { id: row.original.id.toLowerCase() }));
  });

  const columns: Column<OrdersReceived_OrderReceivedFragment>[] = useMemo(
    () => [
      {
        Header: 'Číslo dokladu',
        accessor: 'moneyNumber',
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }) => {
          return (
            <CenteredCell>
              <strong>{value}</strong>
            </CenteredCell>
          );
        },
        maxWidth: 100,
        width: 100,
      },
      {
        Header: 'Odberateľ - firma',
        accessor: 'moneyAddressName',
        width: 150,
      },
      {
        Header: 'Popis',
        accessor: 'moneyName',
        width: 300,
      },
      ...((!customFilter.needAccount
        ? [
            {
              Header: 'Zodpovedný account',
              accessor: 'assignedTo',
              disableSortBy: true,
              width: 120,
              Cell: UserCell,
              Filter: UserFilter,
              sortByToGraphQLVariable: () => 'assignedTo.fullName',
            },
          ]
        : []) as Column<OrdersReceived_OrderReceivedFragment>[]),
      {
        Header: 'Dátum prijatia',
        accessor: 'moneyCreatedAt',
        Filter: DateFilter,
        Cell: DateCell,
        maxWidth: 120,
        width: 120,
      },
      {
        Header: 'Dátum expedície',
        accessor: 'expeditionAt',
        Filter: DateFilter,
        Cell: DateCell,
        maxWidth: 120,
        width: 120,
      },
      {
        Header: 'Dátum montáže',
        accessor: 'assemblyAt',
        Filter: DateFilter,
        Cell: DateCell,
        maxWidth: 120,
        width: 120,
      },
      {
        Header: 'Dátum obhliadky',
        accessor: 'inspectionAt',
        Filter: DateFilter,
        Cell: DateCell,
        maxWidth: 120,
        width: 120,
      },
      {
        Header: 'Spôsob dopravy',
        accessor: 'moneyDeliveryTypeId',
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }) => <div>{moneyDeliveryTypesById[value]?.name || '-'}</div>,
        // eslint-disable-next-line react/display-name
        Filter: (props) => <SelectFilter {...props} options={moneyDeliveryTypeOptions} />,
        filterToGraphQLVariable: (value) => ({ eq: value }),
        maxWidth: 120,
        width: 120,
        disableFilters: customFilter.dpdExpedition || customFilter.dpdPackaging || customFilter.pickup,
      },
      ...((requestUser.role === Role.Developer
        ? [
            {
              Header: 'Spôsob platby',
              accessor: 'moneyPaymentTypeId',
              // eslint-disable-next-line react/display-name
              Cell: ({ cell: { value } }) => <div>{moneyPaymentTypesById[value]?.name || '-'}</div>,
              // eslint-disable-next-line react/display-name
              Filter: (props) => <SelectFilter {...props} options={moneyPaymentTypeOptions} />,
              filterToGraphQLVariable: (value) => ({ eq: value }),
              maxWidth: 120,
              width: 120,
            },
          ]
        : []) as Column<OrdersReceived_OrderReceivedFragment>[]),
      {
        Header: 'Fáza obj.',
        accessor: 'phase',
        Cell: OrderReceivedPhaseCell,
        Filter: OrderReceivedPhaseFilter,
        width: 150,
        disableFilters: customFilter.dpdExpedition || customFilter.dpdPackaging || customFilter.pickup,
      },
      {
        Header: 'Stav výroby',
        accessor: 'status',
        Cell: OrderReceivedStatusCell,
        Filter: OrderReceivedStatusFilter,
        width: 150,
      },
    ],
    [
      customFilter,
      moneyDeliveryTypesById,
      moneyDeliveryTypeOptions,
      moneyPaymentTypesById,
      moneyPaymentTypeOptions,
      requestUser,
    ],
  );

  const modifyVariables = useCallback(
    (variables): { filter: OrderReceivedFilter } => {
      const additionalFilters: OrderReceivedFilter[] = [];
      if (customFilter.needAccount === true) {
        additionalFilters.push({
          phase: {
            not: true,
            in: [OrderReceivedPhase.DONE, OrderReceivedPhase.CANCELED],
          },
        });
        additionalFilters.push({
          OR: [
            {
              assignedTo: {
                id: { eq: requestUser.id },
              },
              phase: {
                not: true,
                in: [
                  OrderReceivedPhase.INVOICE,
                  OrderReceivedPhase.PRODUCTION,
                  OrderReceivedPhase.ASSEMBLY,
                  OrderReceivedPhase.PACKAGING,
                  OrderReceivedPhase.DELIVERY,
                ],
              },
            },
            {
              phase: {
                eq: OrderReceivedPhase.PRODUCTION,
              },
              products: {
                tasks: {
                  user: {
                    id: { eq: requestUser.id },
                  },
                },
                status: {
                  not: true,
                  in: [ProductStatus.DONE, ProductStatus.CANCELED],
                },
              },
            },
            {
              phase: {
                eq: OrderReceivedPhase.ASSEMBLY,
              },
              tasks: {
                type: TaskType.Assembly,
                user: {
                  id: { eq: requestUser.id },
                },
              },
            },
            {
              phase: {
                eq: OrderReceivedPhase.PACKAGING,
              },
              tasks: {
                OR: [
                  { id: { none: true } },
                  {
                    type: TaskType.Packaging,
                    user: {
                      id: { eq: requestUser.id },
                    },
                  },
                ],
              },
            },
            {
              phase: {
                eq: OrderReceivedPhase.DELIVERY,
              },
              tasks: {
                OR: [
                  { id: { none: true } },
                  {
                    type: TaskType.Delivery,
                    user: {
                      id: { eq: requestUser.id },
                    },
                  },
                ],
              },
            },
            {
              AND: [
                {
                  phase: {
                    eq: OrderReceivedPhase.EXPEDITION,
                  },
                },
                {
                  OR: [
                    {
                      moneyDeliveryTypeId: {
                        in: [
                          DeliveryTypes.COURIER_SPS,
                          DeliveryTypes.COURIER_TNT,
                          DeliveryTypes.COURIER_TOPTRANS,
                          DeliveryTypes.COURIER_DPD_DS,
                          DeliveryTypes.COURIER_GEIS_PARCEL,
                          DeliveryTypes.COURIER_SLOVAK_POST,
                          DeliveryTypes.ZASIELKOVNA,
                        ],
                      },
                      tasks: {
                        id: { none: true },
                      },
                    },
                    {
                      tasks: {
                        type: TaskType.Expedition,
                        user: {
                          id: { eq: requestUser.id },
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        });
      }

      const orFilters: OrderReceivedFilter[] = [];
      if (customFilter.dpdExpedition) {
        orFilters.push({
          phase: {
            eq: OrderReceivedPhase.EXPEDITION,
          },
          moneyDeliveryTypeId: {
            in: [DeliveryTypes.COURIER_DPD_DS],
          },
        });
      }

      if (customFilter.dpdPackaging) {
        orFilters.push({
          phase: {
            eq: OrderReceivedPhase.PACKAGING,
          },
          moneyDeliveryTypeId: {
            in: [DeliveryTypes.COURIER_DPD_DS],
          },
        });
      }

      if (customFilter.pickup) {
        orFilters.push({
          phase: {
            eq: OrderReceivedPhase.EXPEDITION,
          },
          moneyDeliveryTypeId: {
            in: [DeliveryTypes.PICKUP],
          },
        });
      }

      if (orFilters.length) {
        additionalFilters.push({
          OR: orFilters,
        });
      }

      if (additionalFilters.length) {
        return {
          ...variables,
          filter: {
            AND: [
              {
                ...variables.filter,
              },
              ...additionalFilters,
            ],
          },
        };
      } else {
        return variables;
      }
    },
    [customFilter, requestUser],
  );

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faCommentsDollar} /> {t(ORDERS_RECEIVED_TITLE)}
          </>
        }
        actions={
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <ButtonGroup size="small">
              <Button
                disableElevation
                color="primary"
                variant={customFilter.dpdExpedition ? 'contained' : 'outlined'}
                onClick={() => {
                  setCustomFilter((state) => ({
                    ...state,
                    dpdExpedition: !state.dpdExpedition,
                  }));
                  if (reactTableRef.current) {
                    reactTableRef.current.setAllFilters([]);
                  }
                }}
              >
                Kuriér DPD
              </Button>{' '}
              <Button
                disableElevation
                color="primary"
                variant={customFilter.dpdPackaging ? 'contained' : 'outlined'}
                onClick={() => {
                  setCustomFilter((state) => ({
                    ...state,
                    dpdPackaging: !state.dpdPackaging,
                  }));
                  if (reactTableRef.current) {
                    reactTableRef.current.setAllFilters([]);
                  }
                }}
              >
                Balenie na kuriéra
              </Button>
              <Button
                disableElevation
                color="primary"
                variant={customFilter.pickup ? 'contained' : 'outlined'}
                onClick={() => {
                  setCustomFilter((state) => ({
                    ...state,
                    pickup: !state.pickup,
                  }));
                  if (reactTableRef.current) {
                    reactTableRef.current.setAllFilters([]);
                  }
                }}
              >
                Osobný odber
              </Button>
            </ButtonGroup>

            <ButtonGroup size="small">
              <Button
                disableElevation
                color="primary"
                variant={customFilter.needAccount ? 'contained' : 'outlined'}
                onClick={onNeedAccountClick}
              >
                Vyžaduje accounta
              </Button>{' '}
              <Button
                disableElevation
                color="primary"
                variant={customFilter.needAccount ? 'outlined' : 'contained'}
                onClick={onShowAllClick}
              >
                Zobraziť všetko
              </Button>
            </ButtonGroup>
          </Box>
        }
      />

      <PagePaper>
        {error || (loading && !data) ? (
          <Loading error={error} />
        ) : (
          <GraphQLReactTable
            ref={reactTableRef}
            id="orders-received"
            columns={columns}
            defaultSortBy={defaultSortBy}
            query={OrdersReceived_LoadTable}
            mapCount={mapCount}
            mapData={mapData}
            onRowClick={onRowClick}
            variables={modifyVariables}
            getRowSx={getRowSx}
            canPrint={false}
            canXLSXExport={false}
          />
        )}
      </PagePaper>
    </>
  );
};

export default authorized(Permission.OrdersReceivedRead)(Page);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  if (ENV.SERVER) {
    const result = await apollo.query({ query: OrdersReceived_Load });
    return result.data;
  }
  return null;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Objednávky',
    },
    {
      text: 'Objednávky prijaté',
    },
  ],
  meta: ({ locale: { t }, meta }: MetaFunctionArgs) => ({
    title: `Objednávky prijaté | ${meta.title}`,
  }),
};
