import { ORDER_RECEIVED_STATUS_NAMES, statusSx } from '@asaprint/common/constants/OrderReceived.js';
import { Box } from '@mui/material';
import React from 'react';

function OrderReceivedStatusCell({ value }: { value: string }) {
  return (
    <Box
      sx={{
        textAlign: 'center',
        borderRadius: '1em',
        mx: 1,
        px: 2,
        width: 'calc(100% - 10px)',
        ...statusSx[value],
      }}
    >
      {ORDER_RECEIVED_STATUS_NAMES[value]}
    </Box>
  );
}

OrderReceivedStatusCell.displayName = 'OrderReceivedStatusCell';

export default OrderReceivedStatusCell;
