import { FilterComponent } from '@asaprint/asap/components/tables/interfaces.js';
import { phaseOptions } from '@asaprint/common/constants/OrderReceived.js';
import SelectFilter from '@engined/client/components/table/SelectFilter.js';
import { StringFilter as SchemaStringFilter } from '@asaprint/asap/schema.client.types.js';
import React from 'react';

const OrderReceivedPhaseFilter: FilterComponent<any, SchemaStringFilter> = (props) => {
  return <SelectFilter {...props} options={phaseOptions} />;
};

OrderReceivedPhaseFilter.displayName = 'OrderReceivedPhaseFilter';

OrderReceivedPhaseFilter.filterToGraphQLVariable = (value) => {
  return {
    eq: value,
  };
};

export default React.memo(OrderReceivedPhaseFilter);
